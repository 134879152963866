<template>
  <expenses-report></expenses-report>
</template>

<script>
import ExpensesReport from '@/components/Reports/Accounting/ExpensesReport'

export default {
  components: { ExpensesReport },
}
</script>
